import { extendTheme } from '@chakra-ui/react';
import { theme as cardTheme } from './theme/Card';
import { theme as formTheme } from './theme/Form';
import { theme as inputTheme } from './theme/Input';

import discoverSecurityPageTopDesktop from '../assets/images/discover-security-bg-desktop.png';
import discoverSecurityPageTopMobile from '../assets/images/discover-security-bg-mobile.png';

export const getComponentStyles = (componentName: string) => {
    return appTheme?.components[componentName] || {};
};

export const getWidgetStyles = (widgetName: string) => {
    return appTheme?.components.Widget[widgetName] || {};
};

export const getPageStyles = (pageName: string) => {
    return appTheme?.pages[pageName] || {};
};

export const getGlobalStyles = () => {
    return appTheme?.styles.global || {};
};

const baseStyles = {
    styles: {
        global: {
            html: {},
            body: {
                lineHeight: '124%',
            },
            center: {
                margin: '0 auto',
                float: 'none',
            },
            page: {
                marginBottom: { base: 0, md: '10px' },
                padding: 0,
                width: '100%',
            },
            bigTitle: {
                fontSize: { base: '32px', md: '36px' },
                fontWeight: 700,
            },
            title: {
                fontSize: { base: '24px', md: '32px' },
                fontWeight: { base: 700, md: 700 },
            },
            subtitle1: {
                fontSize: { base: '18px', md: '24px' },
            },
            boxRadius: {
                borderRadius: { base: '8px', md: '8px' },
            },
            capitalize: {
                textTransform: 'capitalize',
            },
            bold: {
                fontWeight: { base: 700, md: 700 },
            },
            form: {
                width: 'full',
            },
        },
    },
    fonts: {
        heading: `'ApercuPro', sans-serif`,
        body: `'ApercuPro', sans-serif`,
    },
    colors: {
        brand: {
            primary: {
                100: '#F7F1FE',
                200: '#F3E9FB',
                250: '#F3DAFF',
                300: '#E9D7F9',
                350: '#CB5AFF',
                400: '#8C2DDC',
                500: '#8223D2', // main asurion
                600: '#6700B8',
                700: '#3C0C64',
            },
        },
        grayscale: {
            10: '#F7F7F7',
            20: '#A5AAAF',
            30: '#E6E6EB',
            50: '#D3D3D3',
            100: '#C4C4C4',
            200: '#6E767D',
            300: '#757575',
            400: '#F0F0F5', // main gray
            500: '#D5D6DA',
            550: '#DDDDDD',
            600: '#E6E6E6',
            690: '#333333',
            700: '#333F48',
            750: '#445460',
            800: '#979797',
        },
        progress: {
            500: '#333F48',
        },
        black: {
            100: '#111111',
        },
        highRisk: {
            500: '#DB1B1B',
            100: '#FEF6F6',
        },
        // TODO: Change colors
        mediumRisk: {
            100: 'var(--chakra-colors-orange-100)',
            500: 'var(--chakra-colors-orange-500)',
        },
        lowRisk: {
            100: 'var(--chakra-colors-green-100)',
            500: 'var(--chakra-colors-green-500)',
        },
        bgHeader: 'black.100',
    },
};

const pageStyles = {
    pages: {
        ProtectedPageLayout: {
            main: {
                direction: 'row',
                pt: { base: '50px', md: '64px' },
                w: '100%',
            },
        },
        BreachDetailsPage: {
            main: {
                ...baseStyles.styles.global.page,
            },

            tabs: {
                root: {
                    marginTop: { base: '-55px', md: '-65px' },
                    padding: { base: 0, md: '0 10px' },
                },
                tabItem: {
                    backgroundColor: 'white',
                    color: 'grayscale.200',
                    marginRight: '4px',
                    opacity: '0.5',
                    fontSize: '16px',
                    ...baseStyles.styles.global.bold,
                },
                tabItemSelected: {
                    opacity: 1,
                    color: 'black',
                },
                tabPanels: {
                    backgroundColor: 'white',
                },
                tabPanelTitle: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 700, md: 700 },
                },
            },

            items: {
                root: {
                    ...baseStyles.styles.global.boxRadius,
                    border: '1px solid #DDD',
                    maxWidth: '700px',
                    p: '30px 0 30px 16px',
                    mt: '8px',
                },
                name: {
                    fontSize: { base: '16px', md: '18px' },
                    ...baseStyles.styles.global.bold,
                },
                action: {
                    border: '1px solid #DB1B1B',
                    borderRadius: '4px',
                    fontWeight: 400,
                    fontSize: '12px',
                    padding: '4px',
                },
                resolved: {
                    border: '1px solid #008662',
                    borderRadius: '4px',
                    fontWeight: 400,
                    fontSize: '12px',
                    padding: '4px',
                },
                link: {
                    textDecoration: 'none',
                    width: '94px',
                    backgroundColor: 'transparent',
                    color: 'black',
                    fontSize: '14px',
                    ...baseStyles.styles.global.bold,
                },
            },
            field: {
                label: {
                    ...baseStyles.styles.global.capitalize,
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 700, md: 700 },
                    marginTop: '18px',
                },
                value: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 400, md: 400 },
                },
                maskedBox: {
                    backgroundColor: '#FFE8E8',
                    padding: '8px 16px',
                },
                guideBox: {
                    fontSize: { base: '18px', md: '18px' },
                    fontWeight: { base: 700, md: 700 },
                    backgroundColor: 'grayscale.400',
                    padding: '12px 16px',
                },
                stepTitle: {
                    fontSize: { base: '18px', md: '18px' },
                    fontWeight: { base: 700, md: 700 },
                },
                stepText: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 400, md: 400 },
                },
                stepNumber: {
                    borderRadius: '50%',
                    fontSize: '12px',
                    ...baseStyles.styles.global.bold,
                    backgroundColor: 'black',
                    padding: '6px',
                    color: 'white',
                    width: '28px',
                    height: '28px',
                    textAlign: 'center',
                },
                resolve: {
                    fontSize: '16px',
                    ...baseStyles.styles.global.bold,
                },
                resolveTick: {
                    borderRadius: '4px',
                    border: '1px solid #C4C4C4',
                    w: '20px',
                    h: '20px',
                    mr: '8px',
                    float: 'left',
                    cursor: 'pointer',
                },
                resolveSpinner: {
                    mr: '8px',
                    float: 'left',
                },
            },
        },
        ProfilePage: {
            main: {
                ...baseStyles.styles.global.page,
            },
            welcome: {
                root: {
                    height: 'auto',
                    backgroundColor: 'grayscale.400',
                    margin: 0,
                    padding: { base: '14px 14px 0 14px ', md: '24px 24px 0 24px' },
                },
                title: {
                    ...baseStyles.styles.global.bigTitle,
                    lineHeight: { base: '29.76px', md: '39.68px' },
                },
                subtitle: {
                    fontSize: '24px',
                    lineHeight: '29.76px',
                },
            },
            card: {
                desktop: {
                    root: {
                        w: '100%',
                        maxWidth: '1074px',
                        borderRadius: `8px`,
                    },
                    body: {
                        padding: 0,
                    },
                    footer: {
                        padding: 0,
                        border: 0,
                        borderTop: 'none',
                    },
                },
                mobile: {
                    root: {
                        w: '100%',
                        maxWidth: '343px',
                        borderRadius: `16px`,
                        boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.08)`,
                        mb: '16px',
                    },
                    headerSecondary: {
                        backgroundColor: 'grayscale.400',
                    },
                    headerPrimary: {
                        backgroundColor: 'grayscale.700',
                    },
                    field: {
                        fontWeight: 700,
                        fontSize: '14px',
                    },
                    value: {
                        fontWeight: 400,
                        fontSize: '18px',
                    },
                },
            },
            emails: {
                root: {
                    backgroundColor: 'grayscale.400',
                    margin: 0,
                    padding: { base: '14px 14px 0 14px ', md: '24px 24px 0 24px' },
                },
                title: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: { base: '29.76px', md: '39.68px' },
                },
                subtitle: {
                    fontSize: { base: '16px', md: '18px' },
                    fontWeight: 400,
                    lineHeight: { base: '19.84px', md: '29.76px' },
                },
                name: {
                    fontSize: '18px',
                    fontWeight: 700,
                },
                field: {
                    fontSize: '14px',
                    fontWeight: 400,
                },
                subfield: {
                    color: 'grayscale.200',
                },
                btnadd: {
                    backgroundColor: 'white',
                    color: 'black',
                    w: '158px',
                    m: '16px 0 45px 0',
                },
            },
        },
        BreachPage: {
            main: {
                ...baseStyles.styles.global.page,
            },
            welcome: {
                root: {
                    height: { base: 'auto', md: '212px' },
                    backgroundColor: 'grayscale.400',
                    margin: 0,
                    padding: { base: '14px', md: '24px' },
                },
                title: {
                    ...baseStyles.styles.global.title,
                    lineHeight: { base: '29.76px', md: '39.68px' },
                },
                subtitle1: {
                    ...baseStyles.styles.global.subtitle1,
                    fontWeight: { base: 400, md: 400 },
                    lineHeight: { base: '22.32px', md: '29.76px' },
                },
                subtitle2: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '17.36px',
                    color: 'grayscale.200',
                },
                button: {
                    width: { base: '133px', md: '154px' },
                    heignt: { base: '44px', md: '54px' },
                    marginTop: { base: 0, md: '10px' },
                },
            },
            tabs: {
                root: {
                    marginTop: { base: 0, md: '-65px' },
                    padding: { base: 0, md: '0 10px' },
                },
                tabItem: {
                    backgroundColor: 'white',
                    color: 'grayscale.200',
                    marginRight: '4px',
                    opacity: '0.5',
                },
                tabItemAdd: {
                    backgroundColor: 'grayscale.700',
                },
                tabItemLabel: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 700, md: 700 },
                    lineHeight: { base: '19.84px', md: '19.84px' },
                },
                tabItemText: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 400, md: 400 },
                    lineHeight: { base: '19.84px', md: '19.84px' },
                    color: 'grayscale.200',
                },
                tabItemAddText: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 700, md: 700 },
                    lineHeight: { base: '19.84px', md: '19.84px' },
                    color: 'white',
                },
                btnAdd: {
                    fontSize: '14px',
                    ...baseStyles.styles.global.bold,
                    width: '120px',
                    padding: '10px 5px',
                    margin: '2px 8px',
                },
            },
            alerts: {
                root: {
                    margin: { base: '35px 16px 0 16px', md: '24px 24px 0 24px' },
                },
                title: {
                    fontSize: { base: '24px', md: '24px' },
                    fontWeight: { base: 700, md: 700 },
                },
                status: {
                    fontSize: { base: '16px', md: '18px' },
                    fontWeight: { base: 700, md: 700 },
                },
                count: {
                    fontSize: { base: '48px', md: '48px' },
                    fontWeight: { base: 700, md: 700 },
                },
                borderBox: {
                    ...baseStyles.styles.global.boxRadius,
                    height: 'auto',
                    minHeight: 'unset',
                    maxHeight: 'unset',
                    maxWidth: 'unset',
                    minWidth: 'unset',
                    borderColor: 'grayscale.550',
                    margin: '8px 0',
                },
            },
            display: {
                root: {
                    margin: { base: '35px 16px 0 16px', md: '24px 24px 0 24px' },
                    paddingBottom: '30px',
                },
                title: {
                    fontSize: { base: '24px', md: '24px' },
                    fontWeight: { base: 700, md: 700 },
                },
                subtitle1: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 400, md: 400 },
                },
                categoryRoot: {
                    paddingTop: '18px',
                },
                categoryTitle: {
                    ...baseStyles.styles.global.capitalize,
                    fontSize: { base: '18px', md: '18px' },
                    fontWeight: { base: 700, md: 700 },
                    marginBottom: '8px',
                },
                subcategoryRoot: {
                    default: {
                        ...baseStyles.styles.global.boxRadius,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        width: { base: '164px', md: '195px' },
                        height: { base: '167px', md: '167px' },
                    },
                    resolved: {
                        borderColor: '#008662',
                        backgroundColor: '#F5FFFC',
                    },
                    exposed: {
                        borderColor: '#DB1B1B',
                        backgroundColor: '#FEF6F6',
                    },
                },
                subcategoryIcon: {
                    marginTop: { base: '8px', md: '8px' },
                },
                subcategoryName: {
                    fontSize: { base: '16px', md: '16px' },
                    fontWeight: { base: 700, md: 700 },
                    ...baseStyles.styles.global.capitalize,
                },
                subcategoryStatusLine: {
                    borderTop: '1px solid #000000',
                    borderColor: 'grayscale.550',
                    width: '100%',
                    padding: '8px 8px 0 8px',
                },
                subcategoryStatusText: {
                    fontSize: { base: '14px', md: '14px' },
                    fontWeight: { base: 400, md: 400 },
                },
            },
            menu: {
                root: {
                    padding: '8px',
                    margin: '8px 0 8px 0',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'grayscale.100',
                    borderRadius: '4px',
                    backgroundColor: 'white',
                },
            },
        },
        DiscoverSecurityPage: {
            main: {
                ...baseStyles.styles.global.page,
            },
            top: {
                width: '100%',
                height: '200px',
                background: {
                    base: `linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url(${discoverSecurityPageTopMobile});`,
                    md: `linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url(${discoverSecurityPageTopDesktop});`,
                },
                flex: 'none',
                order: 0,
                alignSelf: 'stretch',
                flexGrow: 0,
            },
            title: {
                ...baseStyles.styles.global.title,
                color: 'white',
                margin: { base: '50px 0 8px 16px', md: '60px 0 8px 24px' },
            },
            subtitle1: {
                ...baseStyles.styles.global.subtitle1,
                color: 'white',
                marginLeft: { base: '16px', md: '24px' },
            },
            filter: {
                padding: { base: '10px 18px', md: '16px 24px' },
                backgroundColor: 'grayscale.400',
            },
            filterTitle: {
                fontSize: { base: '16px', md: '16px' },
                ...baseStyles.styles.global.bold,
                color: 'grayscale.200',
            },
            filterTag: {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'grayscale.100',
                ...baseStyles.styles.global.boxRadius,
                backgroundColor: 'white',
                padding: '12px',
                ...baseStyles.styles.global.capitalize,
                ...baseStyles.styles.global.bold,
                cursor: 'pointer',
                color: 'black',
            },
            article: {
                title: {
                    fontSize: { base: '14px', md: '14px' },
                    ...baseStyles.styles.global.bold,
                    color: 'brand.primary.500',
                },
                subtitle: {
                    fontSize: { base: '16px', md: '18px' },
                    ...baseStyles.styles.global.bold,
                },
                image: {
                    margin: '0 auto',
                },
                card: {
                    width: { base: '164px', md: '342px' },
                    height: { base: '355px', md: '420px' },
                },
                date: {
                    fontSize: { base: '14px', md: '14px' },
                    color: 'grayscale.200',
                },
            },
        },
    },
};

const widgetStyles = {
    Widget: {
        AddEmailModal: {
            content: {
                borderRadius: { base: '20px 20px 0 0', md: '8px 8px 0px 0px' },
                position: { base: 'absolute', md: 'relative' },
                bottom: { base: 0, md: 'unset' },
                margin: { base: 0, md: '' },
            },
            header: {
                background: `linear-gradient(91.62deg, #445460 0%, #333F48 100%);`,
                borderRadius: '8px 8px 0 0',
                fontSize: '16px',
                fontWeight: 400,
                color: 'white',
                paddingTop: '24px',
            },
            title: {
                fontSize: '24px',
                fontWeight: 700,
            },
            body: {
                fontSize: '14px',
                fontWeight: 700,
                mb: '16px',
            },
        },
        AddEmailVerifyModal: {
            content: {
                borderRadius: { base: '20px 20px 0 0', md: '8px 8px 0px 0px' },
                position: { base: 'absolute', md: 'relative' },
                bottom: { base: 0, md: 'unset' },
                margin: { base: 0, md: '' },
            },
            header: {
                background: `linear-gradient(91.62deg, #F0F0F5 0%, #E6E6EB 100%);`,
                borderRadius: '8px 8px 0 0',
                fontSize: '16px',
                fontWeight: 400,
                color: 'black',
                paddingTop: '24px',
            },
            title: {
                fontSize: '24px',
                fontWeight: 700,
            },
            body: {
                fontSize: '14px',
                fontWeight: 700,
                mb: '16px',
            },
        },
        Common: {
            heading: {
                fontSize: '18px',
                ...baseStyles.styles.global.bold,
                mb: '8px',
            },
            body: {
                fontSize: { base: '16px', md: '18px' },
                fontWeight: 400,
            },
        },
        Breach: {
            overview: {
                m: '0 24px 0 0',
                boxShadow: `inset 0px -4px 0px #6B8BFF`,
            },
            summary: {
                mt: '16px',
                mb: '16px',
            },
            category: {
                fontSize: { base: '16px', md: '18px' },
                ...baseStyles.styles.global.bold,
                lineHeight: '19px',
                ...baseStyles.styles.global.capitalize,
            },
            subcategory: {
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px',
                ...baseStyles.styles.global.capitalize,
            },
            accordionItem: {
                paddingLeft: 0,
                border: 0,
            },
            accordionItemButton: {
                paddingLeft: 0,
                width: '100%',
            },
            accordionPanel: {
                padding: 0,
            },
            breachCount: {
                borderWidth: '1px',
                borderColor: '#DB1B1B',
                borderStyle: 'solid',
                width: 'auto',
                height: '23px',
                padding: '4px 8px',
                margin: { base: '8px 0 8px 0', md: 0 },
                fontSize: '12px',
                fontWeight: 400,
                color: '#111',
            },
            emailSelector: {
                root: {
                    padding: '8px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'grayscale.100',
                    ...baseStyles.styles.global.boxRadius,
                    // maxWidth: '150px',
                },
                placeholder: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '14px',
                    marginTop: '6px',
                },
                list: {
                    fontSize: '14px',
                },
            },
        },
        Security: {
            mainBox: {
                height: 'auto',
                width: '120px',
                float: 'none',
                margin: '0 auto',
            },
            mainBoxWithChart: {
                height: 'auto',
                width: '100%',
            },
            graph: {
                colorScale: {
                    HIGH: '#DB1B1B',
                    MEDIUM: '#EDB800',
                    LOW: '#008662',
                },
            },
            donut: {
                colorScale: {
                    HIGH: ['#DB1B1B', '#F0F0F5'],
                    MEDIUM: ['#EDB800', '#F0F0F5'],
                    LOW: ['#008662', '#F0F0F5'],
                },
                title: {
                    fontSize: '48px',
                    ...baseStyles.styles.global.bold,
                    fontFamily: `'ApercuPro', sans-serif`,
                },
                subtitle: {
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'grayscale.400',
                    fontFamily: `'ApercuPro', sans-serif`,
                    opacity: 0.5,
                },
            },
            badgeBox: {
                textAlign: 'center',
                marginTop: '16px',
                marginBottom: '20px',
            },
            badgeRisk: {
                root: {
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 400,
                    color: 'black.100',
                    ...baseStyles.styles.global.capitalize,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                },
                colorScheme: {
                    HIGH: { border: 'red', bg: '#FEF6F6' },
                    MEDIUM: { border: 'yellow', bg: '#FFFDF5' },
                    LOW: { border: 'green', bg: '#F5FFFC' },
                },
            },
            recom: {
                label: {
                    fontSize: '12px',
                    ...baseStyles.styles.global.bold,
                    color: '#6E767D',
                    textTransform: 'uppercase',
                },
                title: {
                    fontSize: '24px',
                    ...baseStyles.styles.global.bold,
                },
                show: {
                    fontSize: '16px',
                    fontWeight: 400,
                    color: 'black.100',
                },
                content: {
                    fontSize: '16px',
                    fontWeight: 400,
                },
            },
        },
        Ads: {
            title: {
                fontSize: '18px',
                ...baseStyles.styles.global.bold,
                lineHeight: '22.32px',
            },
            content: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '19.84px',
            },
        },
        Discover: {
            newArticles: {
                width: '342px',
                height: { base: 'auto', md: '340px' },
                margin: { base: '0 auto', md: 'unset' },
            },
            newArticlesBox: {
                width: { base: '343px', md: '342px' },
                maxWidth: '342px',
                minWidth: '342px',
                maxHeight: '338px',
                overflowY: 'scroll',
                borderRadius: '16px 16px 0px 0px',
            },
            newArticleItemBox: {
                padding: '10px 0 12px 0',
                borderBottom: '1px dashed gray',
                borderColor: 'grayscale.550',
            },
            discoverArticlesBox: {
                width: { base: '343px', md: '708px' },
                margin: { base: '0 auto', md: 'unset' },
            },
            discoverArticleItemBox: {
                height: { base: 'auto', md: '337px' },
                minWidth: { base: '342px', md: '220px' },
                maxWidth: { base: '342px', md: '220px' },
                margin: 0,
                padding: 0,
                marginRight: { base: 0, md: '12px' },
                marginLeft: { base: 0, md: '12px' },
                ...baseStyles.styles.global.boxRadius,
                borderColor: 'grayscale.550',
                filter: {
                    base: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08))',
                    md: 'unset',
                },
            },
            title: {
                ...baseStyles.styles.global.bold,
                fontSize: '18px',
                lineHeight: '22.32px',
            },
            subtitle: {
                ...baseStyles.styles.global.bold,
                fontSize: '14px',
                lineHeight: '17.36px',
                color: 'grayscale.200',
            },
            subtitle1: {
                ...baseStyles.styles.global.bold,
                fontSize: '16px',
                lineHeight: '19.84px',
                color: '#111',
            },
            subtitle2: {
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                color: 'grayscale.200',
            },
        },
    },
};

const appTheme = extendTheme({
    ...baseStyles,
    ...pageStyles,

    components: {
        ...widgetStyles,
        WelcomeBoard: {
            main: {
                ...baseStyles.styles.global.title,
                lineHeight: { base: '29.76px', md: '39.68px' },
            },
            note: {
                ...baseStyles.styles.global.subtitle1,
                fontWeight: 400,
                lineHeight: { base: '22.32px', md: '29.76px' },
            },
        },
        Header: {
            main: {
                position: 'fixed',
                width: '100vw',
                height: { base: '52px', md: '64px' },
                p: { base: '12px 16px', md: '18px 24px' },
                backgroundColor: 'black.100',
                zIndex: '99',
            },
            divider: {
                orientation: 'vertical',
                color: 'white',
                h: '28px',
            },
        },
        HeaderMenu: {
            desktop: {
                root: {
                    width: '350px',
                    height: '28px',
                    padding: 0,
                    margin: 0,
                    display: { base: 'none', md: 'block' },
                },
            },
            mobile: {
                toggle: {
                    display: { base: 'block', md: 'none' },
                },
                root: {
                    width: '220px',
                    height: '100vh',
                    backgroundColor: 'grayscale.700',
                    position: 'absolute',
                    top: 0,
                    right: '0px',
                    zIndex: 9999,
                },
                linkItem: {
                    textDecoration: 'none',
                    color: 'white',
                },
                linkIcon: {
                    marginRight: '20px',
                    marginLeft: '15px',
                },
            },
        },
        SidebarNav: {
            main: {
                minHeight: `calc(100vh - 64px)`,
                width: '220px',
                backgroundColor: 'grayscale.700',
                boxShadow: 'none',
                display: { base: 'none', md: 'block' },
            },
            item: {
                inactive: {
                    w: '100%',
                    boxShadow: 'none',
                    backgroundColor: 'grayscale.700',
                    cursor: 'pointer',
                    textDecoration: 'none',
                },
                link: {
                    textDecoration: 'none',
                },
                active: {
                    w: '100%',
                    backgroundColor: '#2A343C',
                    boxShadow: 'inset 4px 0px 0px #8223D2',
                    textDecoration: 'none',
                },
                text: {
                    m: '16px auto 26px auto',
                    w: '120px',
                    textAlign: 'center',
                    float: 'none',
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 400,
                },
            },
            exposedCount: {
                position: 'absolute',
                top: 0,
                right: '23px',
                width: '28px',
                height: '28px',
                backgroundColor: '#8223D2',
                borderRadius: '50%',
                padding: '4px',
                fontSize: '12px',
                ...baseStyles.styles.global.bold,
            },
        },

        Card: cardTheme,
        OverviewCard: {
            root: {
                default: {
                    borderRadius: `16px 16px 0 0`,
                    boxShadow: `inset 0px -4px 0px #E6E6EB`,
                    m: { base: '0 auto', md: '0' },
                    w: '342px',
                    h: 'auto',
                },
                highlight: {
                    borderRadius: `16px 16px 0 0`,
                    boxShadow: `inset 0px -4px 0px #333F48`,
                    m: { base: '0 auto', md: '0 24px 0 0' },
                    w: '342px',
                    h: 'auto',
                },
            },
            header: {
                default: {
                    backgroundColor: 'grayscale.30',
                    fontSize: '18px',
                    ...baseStyles.styles.global.bold,
                    paddingBottom: '17px',
                    paddingTop: '15px',
                },
                highlight: {
                    backgroundColor: 'grayscale.700',
                    color: 'white',
                    fontSize: '18px',
                    ...baseStyles.styles.global.bold,
                    paddingBottom: '17px',
                    paddingTop: '15px',
                },
            },
        },
        Form: formTheme,
        Input: inputTheme,
        Link: {
            baseStyle: {
                textDecoration: 'none',
            },
        },
        Button: {
            baseStyle: {
                w: 'full',
                py: 4,
                h: 'auto',
            },
            variants: {
                link: {
                    fontSize: '16px',
                    color: 'white',
                    textDecoration: 'underline',
                    h: 'auto',
                },
                outline: {
                    borderColor: 'grayscale.100',
                    h: 'auto',
                },
                solid: {
                    h: 'auto',
                },
            },
            defaultProps: {
                colorScheme: 'brand.primary',
                height: 'auto',
            },
        },
        Heading: {
            sizes: {
                large: {
                    fontSize: '32px',
                    lineHeight: '39.68px',
                },
                medium: {
                    fontSize: '28px',
                },
                small: {
                    fontWeight: 400,
                    fontSize: '24px',
                    lineHeight: '29.76px',
                },
            },
        },
        Checkbox: {
            baseStyles: {
                fontFamily: `'ApercuPro', sans-serif`,
            },
        },
        Badge: {
            variants: {
                profileTag: {
                    ...baseStyles.styles.global.capitalize,
                    fontSize: '12px',
                    fontWeight: 400,
                    padding: '4px 8px',
                    borderRadius: '4px',
                    borderColor: 'grayscale.700',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: 'grayscale.400',
                },
                breachCount: {
                    ...baseStyles.styles.global.capitalize,
                    borderRadius: '4px',
                    borderWidth: '1px',
                    borderColor: '#DB1B1B',
                    borderStyle: 'solid',
                    width: 'auto',
                    height: '23px',
                    padding: '4px 8px',
                    margin: { base: '8px 0 8px 0', md: 0 },
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#111',
                },
            },
        },
        Spinner: {
            baseStyle: {
                color: 'brand.primary.500',
            },
            variants: {
                pageLoader: {
                    borderWidth: '5px',
                    borderBottomColor: 'brand.primary.300',
                    borderLeftColor: 'brand.primary.300',
                    width: '4rem',
                    height: '4rem',
                },
                menuLoader: {
                    borderWidth: '2px',
                    borderBottomColor: 'brand.primary.300',
                    borderLeftColor: 'brand.primary.300',
                    width: '1.5rem',
                    height: '1.5rem',
                },
                componentLoader: {
                    borderWidth: '2px',
                    borderBottomColor: 'brand.primary.300',
                    borderLeftColor: 'brand.primary.300',
                    width: '2rem',
                    height: '2rem',
                },
                miniLoader: {
                    borderWidth: '2px',
                    borderBottomColor: 'brand.primary.300',
                    borderLeftColor: 'brand.primary.300',
                    width: '1rem',
                    height: '1rem',
                },
            },
            defaultProps: {
                colorScheme: 'brand.primary',
            },
        },
    },
});

export default appTheme;
