import React, { useMemo, useState } from 'react';
import { Badge, Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import OverviewAssessmentSvg from '../../assets/OverviewAssessmentSvg';
import OverviewCard, {
    OverviewFooterLink,
    OverviewTitleCard,
} from '../common/OverviewCard';

import NoAssessmentScoreSvg from '../../assets/NoAssessmentScoreSvg';
import { ChartDonutUtilization, ChartLabel } from '@patternfly/react-charts';
import { AppRoutes, QueryKeys, RISK_LEVEL } from '../../config/config';
import { getGlobalStyles, getWidgetStyles } from '../../config/theme';
import VerticalGraph from '../common/VerticalGraph';
import { useQuery } from '@tanstack/react-query';
import { fetchAssessmentsResultSummary } from '../../services/apiService';
import { account } from '../../entities/session';
import { createUrl, getResponseError, isMobileScreen } from '../../utils/helpers';
import renderDocument from '../../utils/renderDocument';
import RouteLink from '../common/RouteLink';
import useLogout from '../../hooks/useLogout';
import useContent from '../../hooks/useContent';

const SecurityAssessmentWidget = () => {
    const content = useContent();
    const doLogout = useLogout();
    const accountInfo = account.use();
    const email = accountInfo.primaryEmail.email || accountInfo.primaryEmail;

    const { heading, body } = getWidgetStyles('Common');
    const { center } = getGlobalStyles();
    const { donut, mainBox, mainBoxWithChart, badgeBox, badgeRisk, recom } =
        getWidgetStyles('Security');

    const [showRecommendation, setShowRecommendation] = useState<boolean>(
        isMobileScreen() ? false : true
    );

    const {
        data: summaryData,
        error: summaryError,
        isFetching: summaryFetching,
    } = useQuery(
        QueryKeys.assessments.summary(email),
        () => fetchAssessmentsResultSummary({ email }),
        {
            enabled: !!email,
        }
    );

    const hasAssessment = useMemo(() => summaryData?.score! >= 0, [summaryData?.score]);

    if (!summaryFetching && summaryError) {
        const { status } = getResponseError(summaryError);
        if (status === 401) {
            doLogout();
        }
    }

    const NoScoreContent = () => {
        return (
            <Flex direction={'column'} gap={0}>
                <Box textAlign={'center'} width={'100%'} mt={'16px'}>
                    <NoAssessmentScoreSvg style={{ ...center }} />
                </Box>
                <Box mt={'16px'}>
                    <Heading {...heading}>
                        {content('widgets.assessment.noscore')}
                    </Heading>
                    <Text {...body}>{content('widgets.assessment.findout')}</Text>
                </Box>
                <Box mt={'16px'}>
                    <Button
                        variant={'solid'}
                        onClick={() => {
                            window.location.href = createUrl(AppRoutes.ASSESSMENT);
                        }}
                    >
                        {content('widgets.assessment.takeassessment')}
                    </Button>
                </Box>
            </Flex>
        );
    };

    const DonutScore = () => {
        return (
            <Flex direction={'column'}>
                <ChartDonutUtilization
                    constrainToVisibleArea
                    legendComponent={<>sss</>}
                    data={{
                        x: 'Protected from risk',
                        y: summaryData?.score || 0,
                    }}
                    height={175}
                    labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
                    subTitle={'/100'}
                    title={summaryData?.score ? summaryData?.score.toString() : '0'}
                    width={175}
                    colorScale={
                        donut.colorScale[
                            summaryData?.risk?.toUpperCase() || RISK_LEVEL.HIGH
                        ]
                    }
                    titleComponent={<ChartLabel style={donut.title} />}
                    subTitleComponent={<ChartLabel style={donut.subtitle} y={110} />}
                    innerRadius={60}
                    radius={70}
                />
                <Box {...badgeBox}>
                    <Badge
                        variant={'outline'}
                        {...badgeRisk.root}
                        colorScheme={
                            badgeRisk.colorScheme[
                                summaryData?.risk.toUpperCase() || RISK_LEVEL.HIGH
                            ].border
                        }
                        backgroundColor={
                            badgeRisk.colorScheme[
                                summaryData?.risk.toUpperCase() || RISK_LEVEL.HIGH
                            ].bg
                        }
                    >
                        {`${summaryData?.risk.toLowerCase()} risk`}
                    </Badge>
                </Box>
            </Flex>
        );
    };

    const BarGraph = () => {
        const labels: any = summaryData?.monthly.map(item => {
            return item.month;
        });
        const scores: any = summaryData?.monthly.map(item => {
            return { score: item.score, risk: item.risk };
        });
        return (
            <Box>
                <VerticalGraph title="score" labels={labels} scores={scores} />
            </Box>
        );
    };

    const HasScoreContent = () => {
        return (
            <Flex direction={'column'} gap={0}>
                <Box id={'donut-score'}>
                    {summaryData &&
                    summaryData?.score >= 0 &&
                    summaryData?.monthly &&
                    summaryData?.monthly.length > 0 ? (
                        <Box style={mainBoxWithChart}>
                            <Flex direction={'row'}>
                                <DonutScore />
                                <BarGraph />
                            </Flex>
                        </Box>
                    ) : (
                        <Box style={mainBox}>
                            {summaryData && summaryData?.score >= 0 && <DonutScore />}
                        </Box>
                    )}
                </Box>
                <Box id={'assessment-summary'}>
                    <Box>
                        <Text {...recom.label}>
                            {content('widgets.assessment.toprecommendation')}
                        </Text>
                    </Box>
                    {summaryData?.recommendation && (
                        <>
                            <Box>
                                <Text {...recom.title}>
                                    {summaryData?.recommendation.title}
                                </Text>
                            </Box>
                            <Box>
                                <Link
                                    onClick={() =>
                                        setShowRecommendation(!showRecommendation)
                                    }
                                    {...recom.show}
                                >
                                    {content('widgets.assessment.why')}
                                </Link>
                            </Box>
                            {showRecommendation && (
                                <Box {...recom.content}>
                                    {renderDocument(summaryData?.recommendation.content)}
                                </Box>
                            )}
                        </>
                    )}

                    <Box mt={'16px'}>
                        <Button
                            variant={'solid'}
                            onClick={() => {
                                window.location.href = createUrl(
                                    summaryData && summaryData?.allowReTake
                                        ? AppRoutes.ASSESSMENT
                                        : summaryData && summaryData?.score >= 0
                                        ? AppRoutes.ASSESSMENT_RESULT
                                        : AppRoutes.ASSESSMENT
                                );
                            }}
                        >
                            {summaryData?.allowReTake
                                ? content('widgets.assessment.retakeassessment')
                                : summaryData && summaryData?.score >= 0
                                ? content('widgets.assessment.startyourguide')
                                : content('widgets.assessment.takeassessment')}
                            {}
                        </Button>
                    </Box>
                </Box>
            </Flex>
        );
    };

    return (
        <OverviewCard
            id={'assessment'}
            isLoading={summaryFetching}
            title={
                <OverviewTitleCard
                    titleIcon={<OverviewAssessmentSvg />}
                    titleText={content('widgets.assessment.title')}
                />
            }
            footer={
                hasAssessment ? (
                    <Box textAlign={'center'} w={'100%'}>
                        <OverviewFooterLink onClick={() => {}}>
                            <RouteLink to={AppRoutes.ASSESSMENT_RESULT}>
                                View full report
                            </RouteLink>
                        </OverviewFooterLink>
                    </Box>
                ) : null
            }
        >
            {hasAssessment ? <HasScoreContent /> : <NoScoreContent />}
        </OverviewCard>
    );
};

export default SecurityAssessmentWidget;
