import { BoxProps, ChakraProps } from '@chakra-ui/react';
import React from 'react';

// UTILITY TYPES
type AnyCase<T extends string> = string extends T
    ? string
    : T extends `${infer F1}${infer F2}${infer R}`
    ? `${Uppercase<F1> | Lowercase<F1>}${Uppercase<F2> | Lowercase<F2>}${AnyCase<R>}`
    : T extends `${infer F}${infer R}`
    ? `${Uppercase<F> | Lowercase<F>}${AnyCase<R>}`
    : '';

// Component Props
export type ChatWidgetProps = {
    open: boolean;
    components?: ChatWidgetComponents;
    icons?: {
        chat: React.ElementType<ChakraProps>;
        close: React.ElementType<ChakraProps>;
        minimize: React.ElementType<ChakraProps>;
    };
};

export type BaseMessageProps = {
    message: React.ReactNode;
    boxProps?: BoxProps;
};

export type MessageProps = {
    type: AnyCase<MessageTypes>;
    children: React.ReactNode;
};

export type ChatWidgetComponents = {
    message: { [K in AnyCase<MessageTypes>]: React.FC<BaseMessageProps> };
};

// TYPES
export type Message = {
    message: string;
    source: AnyCase<MessageTypes>;
};

export enum MessageTypes {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
}
