import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Flex, Show, Spinner } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchAssessmentGuide, resolveAssessment } from '../services/apiService';
import useErrorToast from '../hooks/useErrorToast';
import { AxiosError } from 'axios';
import { AppRoutes, QueryKeys } from '../config/config';
import Wizard from '../components/common/Wizard/Wizard';
import useWizard from '../hooks/useWizard';
import { GuideOverviewWizardCard } from '../components/assessment/wizardCards/GuideOverviewWizardCard';
import { GuideStepWizardCard } from '../components/assessment/wizardCards/GuideStepWizardCard';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { UserAccount } from './AssessmentResultPage';
import { account } from '../entities/session';

export const AssessmentGuidePage = () => {
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');
    const guide = searchParams.get('guide');
    const accountInfo: UserAccount = account.use();
    const email =
        typeof accountInfo.primaryEmail === 'string'
            ? accountInfo.primaryEmail
            : accountInfo.primaryEmail.email;

    const errorToast = useErrorToast();
    const navigate = useNavigate();

    const { data, isLoading } = useQuery(
        QueryKeys.assessments.guide({ category: category!!, guide: guide!! }),
        {
            queryFn: () => fetchAssessmentGuide(guide as string),
            enabled: !!category && !!guide,
            onError: (error: AxiosError<Error>) => {
                if (error?.response?.status === 404) {
                    errorToast(undefined, {
                        title: 'Error',
                        description: 'Guide not found',
                    });
                } else {
                    errorToast(error);
                }
                navigate(AppRoutes.DASHBOARD);
            },
        }
    );

    const { mutateAsync: resolve } = useMutation({
        mutationFn: resolveAssessment,
    });

    const {
        handleNextStep,
        handlePrevStep,
        progress,
        currStep,
        hasNextStep,
        hasPrevStep,
        currStepData,
    } = useWizard({
        steps: data?.guideSteps,
        hasPre: true,
    });

    if (isLoading) {
        return (
            <Flex align="center" justify="center" w="full" h="100vh">
                <Spinner />
            </Flex>
        );
    }

    return (
        <Flex flexDir="column" w="full" id={'assessment-guide-page'}>
            <Show below="md">
                <Box px={4} pt={3}>
                    <Breadcrumbs
                        items={[
                            {
                                label: 'Guide',
                                href: AppRoutes.ASSESSMENT_RESULT,
                                current: true,
                            },
                        ]}
                        currentOnly
                    />
                </Box>
            </Show>
            <Wizard>
                {currStep === 0 ? (
                    <GuideOverviewWizardCard
                        topTitle={data?.description}
                        topDescription={data?.guideDescription}
                        progress={progress}
                        bottomTitle={data?.guideOverviewTitle}
                        bottomDescription={data?.guideOverviewDescription}
                        prevButtonProps={{
                            disabled: !hasPrevStep,
                            onClick: () => handlePrevStep(),
                        }}
                        nextButtonProps={{
                            disabled: !hasNextStep,
                            onClick: () => handleNextStep(),
                        }}
                    />
                ) : (
                    <GuideStepWizardCard
                        data={currStepData}
                        step={currStep}
                        numSteps={data?.guideSteps?.length}
                        progress={progress}
                        prevButtonProps={{
                            disabled: !hasPrevStep,
                            onClick: () => handlePrevStep(),
                        }}
                        nextButtonProps={{
                            disabled: !hasNextStep,
                            onClick: () => handleNextStep(),
                        }}
                        onSubmit={async () => {
                            console.log({ email, category, guide });
                            try {
                                if (!!email && !!category && !!guide) {
                                    await resolve({ email, category, guide });
                                } else {
                                    throw new Error('Cannot proceed with action.');
                                }
                            } catch (err) {
                                errorToast(err as AxiosError<Error>);
                            }
                        }}
                    />
                )}
            </Wizard>
        </Flex>
    );
};

export default AssessmentGuidePage;
